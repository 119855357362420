.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}


.App-header {
  background-color: #282c34;
  min-height: 0vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

h1 {
  font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  color: #957ca4;
}

p {
  margin-top: 1.5%;
  margin-left: 30%;
  margin-right: 30%;
  font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  font-size: 1em;
}


.logoab{
  background-image: url('./assets/images/logoab_100.png');
  background-repeat: no-repeat;
  background-position: center;
  height: 250px;
  
}

.avatar{
  background-image: url('./assets/images/AvatarAnimation.gif');
  height: 420px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.hero{
  background-image: url('./assets/images/hero1.gif');
  height: 540px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.abhero{
  background-image: url('./assets/images/abhero.gif');
  height: 540px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}


button {
  padding: 10px;
  border: 1px solid gray;
  background-color: #957ca4;
  color: aliceblue;

  border-radius: 5px;
}

nav {
  margin: 1em;
}

nav>.nav-item {
  padding: 1em;
  margin: 1em;
  text-decoration: none;
}



.parallel {
  display: inline-grid;
  grid-template-columns: 1fr 1fr;
  margin-top: 3%;
}


.framel {
  margin-top: 9%;
  margin-left: 9%;
}

.framer {
  margin-top: 9%;
  margin-right: 9%;
}

.foot {
  text-align: center;
  margin-top: 1.5%;
}

.banner {
  background-color: #957ca4;
  display: grid;
  place-items: center;
  padding-top: 3%;
  padding-bottom: 3%;

}

.banner th,
.banner td {
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 9px;
  padding-right: 9px;
}

.banner a {
  text-decoration: none;
  color: aliceblue;
}

.icon {
  display: inline-flex;
  margin: 10px;

}

.icon>div {
  margin: 5px;
}

@media only screen and (max-width: 1200px) {
  .page__description h1 {
    margin-top: 100px;
  }


  h1 {
    font-size: 1rem;
  }

  p {
    margin-top: 1.5%;
    font-size: 0.75rem;
    margin-top: 1.5%;
    margin-left: 15%;
    margin-right: 15%;
  }


  .logoab{
    background-image: url('./assets/images/logoab_100.png');
    height: 60px;
    
  }
  .avatar{
    height: 420px;
  }

  .hero, .abhero {
    height: 270px;
   
  }

  

  .parallel {
    display: block;
    margin-top: 6%;
  }

  .framel {
    margin: 0;
    margin-bottom: 1em ;
    margin-left: 0;
    margin-right: 0;
    align-content: center;


  }

  .framer {
    margin: 0;
    align-content: center;

  }
}


@media only screen and (max-width: 850px) {
  .page__description h1 {
    margin-top: 100px;
  }


  .logoab{
    height: 75px;
    
  }
  .avatar{
    height: 450px;
  }
  
  .hero, .abhero {
    height: 135px;
   
  }

 
  


  p {
    margin-top: 1.5%;
    font-size: 0.75rem;
    margin-top: 1.5%;
    margin-left: 3%;
    margin-right: 3%;
  }

  .parallel {
    display: block;
    
  }

  .framel {
    margin: 0;
    margin-top: 3%;


  }



  .framer {
    margin: 0;
    margin-top: 3%;

  }

  .framel img, .framer img{
    width:  75%;
  }

  img{
    width: 75%;
  }

}
.flipBook {
  display: grid;
  place-items: center;
  width: 100%;
}
.flipBook .buttons {
  width: 100%;
  /* border: 1px solid red; */
  padding-top: 1em;
}
.demoPage {
  border: 1px solid #957ca4;
}